import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@core/http/api-client';
import { AccessCardBatchUploadTimeoutError } from '@core/http/errors';
import { Observable } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import {
  mapAPIBuildingCommonFacilityDTO,
  mapAPIEditableBuilding,
  mapIListResponse,
} from './mappers/common-api';
import {
  mapAPIBuildingFloor,
  mapAPIBuildingParams,
  mapAPICardType,
  mapAPIFacility,
  mapStaff,
  mapAPIStaffAccessSubmission,
  mapStatus,
  mapMassActionResponseCard,
  mapAPIStaffAccessMassEditSubmission,
} from './mappers/staff-access-api';
import {
  APIBuildingCommonFacilityDTO,
  IAPIEditableBuilding,
  IListResponse,
} from './models/common';
import {
  IAPIBuildingFloor,
  IAPICardType,
  IAPIDownloadTemplateResponse,
  IAPIFacility,
  IAPIStaff,
  IAPIStaffAccessRequest,
  IAPIStaffAccessSubmission,
  IAPIStaffAccessSubmissionQuery,
  IAPIStaffAccessSubmissionStatus,
  IAPIStaffAccessTemplateRequest,
  IAPIStaffAccessUpdateForm,
  IAPIStaffAccessUploadUrl,
  IAPIStaffAccessUploadRequest,
  IAPIBuildingParams,
  IAPIStaffAccessExportRequest,
  IAPIStaffAccessExportDownloadStatus,
  IAPIStaffAccessExportDownloadURLResponse,
  IAPIStaffAccessMassCancelRequest,
  IAPIStaffAccessMassCancelResponse,
  IAPIStaffAccessMassActionSubmissionQuery,
  IAPIStaffAccessMassAddFloorsRequest,
  IAPIStaffAccessMassSetDefaultFloorRequest,
  IAPIStaffAccessMassRemoveFloorsRequest,
  IAPIStaffAccessMassEditSubmission,
  IAPIStaffAccessMassAddFacilitiesRequest,
  IAPIStaffAccessMassRemoveFacilitiesRequest,
  APIStaffAccessMassEditActionRequestBase,
} from './models/staff-access';
import { extractFileName } from './utils/attachments';
import { createHttpParams } from './utils/params';

function mapMassEditActionCommonRequestParam(
  r: APIStaffAccessMassEditActionRequestBase
): any {
  return {
    buildingIDs: [r.buildingID],

    status: r.statusCodes,
    cardTypes: r.cardTypeCodes,
    buildingFloorIDs: r.buildingFloorIDs,
    searchTerm: r.search,
    searchFields: r.search
      ? ['CARD_NO', 'STAFF_NAME', 'STAFF_EMAIL', 'STAFF_REMARKS']
      : null,

    staffAccessProfiles: r.selectedAll
      ? []
      : r.cards.map((c) => ({
          staffAccessCardID: c.staffAccessCardID,
          staffAccessProfileID: c.staffAccessProfileID,
        })),
    selectedAll: r.selectedAll,
    unSelectProfiles: r.selectedAll
      ? r.deselectedCards.map((c) => ({
          staffAccessCardID: c.staffAccessCardID,
          staffAccessProfileID: c.staffAccessProfileID,
        }))
      : [],
  };
}

@Injectable({
  providedIn: 'root',
})
export class StaffAccessAPIService {
  constructor(private readonly http: ApiClient) {}

  getBuildings(): Observable<IAPIEditableBuilding[]> {
    return this.http
      .get<IListResponse<IAPIEditableBuilding>>(
        'requests/staff-access/buildings'
      )
      .pipe(map((resp) => resp.list.map(mapAPIEditableBuilding)));
  }

  getCardTypes(buildingID: number): Observable<IAPICardType[]> {
    const params: Record<string, string> = {
      buildingID: String(buildingID),
    };
    return this.http
      .get<IListResponse<IAPICardType>>(`requests/staff-access/card-types`, {
        params,
      })
      .pipe(map((resp) => resp.list.map(mapAPICardType)));
  }

  getFloors(staffAccessCardID?: number): Observable<IAPIBuildingFloor[]> {
    const params: Record<string, string> = {};
    if (staffAccessCardID != null) {
      params.staffAccessCardID = String(staffAccessCardID);
    }
    return this.http
      .get<IListResponse<any>>(`requests/staff-access/buildings/floors`, {
        params,
      })
      .pipe(map((resp) => resp.list.map(mapAPIBuildingFloor)));
  }

  getBuildingFloors(buildingID: number): Observable<IAPIBuildingFloor[]> {
    return this.http
      .get<IListResponse<any>>(
        `requests/staff-access/buildings/${buildingID}/floors`
      )
      .pipe(map((resp) => resp.list.map(mapAPIBuildingFloor)));
  }

  getFacilities(
    buildingID: number,
    staffAccessCardID?: number
  ): Observable<IAPIFacility[]> {
    const params: Record<string, string> = {};
    if (staffAccessCardID != null) {
      params.staffAccessCardID = String(staffAccessCardID);
    }
    return this.http
      .get<IListResponse<any>>(
        `requests/staff-access/buildings/${buildingID}/facilities`,
        { params }
      )
      .pipe(map((resp) => resp.list.map(mapAPIFacility)));
  }

  getBuildingCommonFacility(
    buildingID: number
  ): Observable<APIBuildingCommonFacilityDTO[]> {
    return this.http
      .get(`requests/staff-access/buildings/${buildingID}/common-facilities`)
      .pipe(
        map(mapIListResponse(mapAPIBuildingCommonFacilityDTO)),
        map((dto) => dto.list)
      );
  }

  createRequest(
    request: IAPIStaffAccessRequest,
    allowDuplicate?: boolean
  ): Observable<any> {
    const params: Record<string, string> = {};
    if (allowDuplicate != null) {
      params.allowDuplicate = String(allowDuplicate);
    }
    return this.http
      .post<any>(`requests/staff-access`, request, { params })
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 504) {
            throw new AccessCardBatchUploadTimeoutError();
          }
          throw err;
        })
      );
  }

  editSubmission(
    id: number,
    profileID: number,
    form: IAPIStaffAccessUpdateForm
  ): Observable<void> {
    const params: Record<string, string> = {};
    params.staffAccessProfileID = String(profileID);
    return this.http
      .put(`requests/staff-access/${id}`, form, { params })
      .pipe(map(() => undefined));
  }

  getStatuses(): Observable<IAPIStaffAccessSubmissionStatus[]> {
    return this.http
      .get<IListResponse<any>>('requests/staff-access/status')
      .pipe(map((resp) => resp.list.map(mapStatus)));
  }

  getBuildingParams(id: number): Observable<IAPIBuildingParams> {
    const params: Record<string, string> = {};
    params.buildingID = String(id);
    return this.http
      .get(`requests/staff-access/parameters`, { params })
      .pipe(map((resp) => mapAPIBuildingParams(resp)));
  }

  getSubmission(
    id: number,
    profileID: number
  ): Observable<IAPIStaffAccessSubmission> {
    const params: Record<string, string> = {};
    params.staffAccessProfileID = String(profileID);
    return this.http
      .get<any>(`requests/staff-access/${id}`, { params })
      .pipe(map((resp) => mapAPIStaffAccessSubmission(resp)));
  }

  getSubmissions(
    query: IAPIStaffAccessSubmissionQuery
  ): Observable<IListResponse<IAPIStaffAccessSubmission>> {
    return this.http
      .get<IListResponse<any>>('requests/staff-access/submissions', {
        params: createHttpParams({
          ...query,
          status: query.status?.join(','),
          buildingIDs: query.buildingIDs?.join(','),
          cardTypes: query.cardTypes?.join(','),
          floorIDs: query.floorIDs?.join(','),
          buildingFloorFacilityIDs: query.buildingFloorFacilityIDs?.join(','),
          searchFields: query.searchFields?.join(','),
          page: query.page ? (query.page - 1).toString() : undefined,
          limit: query.limit?.toString(),
        }),
      })
      .pipe(map((resp) => mapIListResponse(resp, mapAPIStaffAccessSubmission)));
  }

  cancelSubmission(
    id: number,
    profileID: number
  ): Observable<IAPIStaffAccessSubmission> {
    const params: Record<string, string> = {};
    params.staffAccessProfileID = String(profileID);
    return this.http.put<any>(`requests/staff-access/${id}/cancel`, null, {
      params,
    });
  }

  revokeCancelSubmission(
    id: number,
    profileID: number
  ): Observable<IAPIStaffAccessSubmission> {
    const params: Record<string, string> = {};
    params.staffAccessProfileID = String(profileID);
    return this.http
      .put<any>(`requests/staff-access/${id}/revoke-cancel`, null, { params })
      .pipe(map(mapAPIStaffAccessSubmission));
  }

  regrantSubmission(
    id: number,
    profileID: number
  ): Observable<IAPIStaffAccessSubmission> {
    const params: Record<string, string> = {};
    params.staffAccessProfileID = String(profileID);
    return this.http
      .put<any>(`requests/staff-access/${id}/regrant`, null, { params })
      .pipe(map(mapAPIStaffAccessSubmission));
  }

  downloadTemplate(
    templateInfo: IAPIStaffAccessTemplateRequest
  ): Observable<IAPIDownloadTemplateResponse> {
    return this.http
      .post(`requests/staff-access/batch/template`, templateInfo, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((resp) => {
          if (!resp.body) {
            throw new Error('Received empty response');
          }
          return {
            fileName: extractFileName(resp.headers) ?? 'template.csv',
            data: resp.body,
          };
        })
      );
  }

  public getUploadUrl(
    fileName: string,
    contentType: string
  ): Observable<IAPIStaffAccessUploadUrl> {
    return this.http
      .post<any>('requests/staff-access/batch/upload-url', {
        fileName,
        contentType,
      })
      .pipe(map((resp) => ({ url: resp.url, objectKey: resp.objectKey })));
  }

  public uploadTemplate(
    request: IAPIStaffAccessUploadRequest
  ): Observable<IAPIStaff[]> {
    return this.http
      .post<any>('requests/staff-access/batch/upload', request)
      .pipe(map((resp) => resp.list.map(mapStaff)));
  }

  public startExport(
    request: IAPIStaffAccessExportRequest
  ): Observable<IAPIStaffAccessExportDownloadStatus> {
    return this.http
      .post<any>('requests/staff-access/report', request)
      .pipe(map((resp) => resp));
  }

  public getExportStatus(
    processID: string
  ): Observable<IAPIStaffAccessExportDownloadStatus> {
    return this.http
      .get<any>(`requests/staff-access/report/${processID}`)
      .pipe(map((resp) => resp));
  }

  public getExportDownloadURL(
    processID: string
  ): Observable<IAPIStaffAccessExportDownloadURLResponse> {
    return this.http
      .get<any>(`requests/staff-access/report/${processID}/download-url`)
      .pipe(map((resp) => resp));
  }

  getSubmissionsForMassAction(
    query: IAPIStaffAccessMassActionSubmissionQuery
  ): Observable<IListResponse<IAPIStaffAccessMassEditSubmission>> {
    return this.http
      .get<IListResponse<any>>('requests/staff-access/submissions/mass', {
        params: createHttpParams({
          status: query.status?.join(','),
          buildingIDs: query.buildingIDs?.join(','),
          cardTypes: query.cardTypes?.join(','),
          buildingFloorIDs: query.floorIDs?.join(','),
          newAccessFloorIDs: query.newAccessFloorIDs?.join(','),
          newDefaultBuildingFloorID:
            query.newDefaultBuildingFloorID != null
              ? String(query.newDefaultBuildingFloorID)
              : undefined,
          toBeRemoveFloorIDs: query.toBeRemoveFloorIDs?.join(','),
          searchTerm: query.searchTerm,
          searchFields: query.searchTerm
            ? ['CARD_NO', 'STAFF_NAME', 'STAFF_EMAIL', 'STAFF_REMARKS'].join(
                ','
              )
            : null,
          page: query.page ? (query.page - 1).toString() : undefined,
          limit: query.limit?.toString(),
          sortBy: query.sortBy,
          order: query.order,
        }),
      })
      .pipe(
        map((resp) =>
          mapIListResponse(resp, mapAPIStaffAccessMassEditSubmission)
        )
      );
  }

  getSubmissionsForFacilityMassAction(
    query: IAPIStaffAccessMassActionSubmissionQuery
  ): Observable<IListResponse<IAPIStaffAccessMassEditSubmission>> {
    return this.http
      .get<IListResponse<any>>(
        'requests/staff-access/submissions/mass/facilities',
        {
          params: createHttpParams({
            status: query.status?.join(','),
            buildingIDs: query.buildingIDs?.join(','),
            cardTypes: query.cardTypes?.join(','),
            searchTerm: query.searchTerm,
            searchFields: query.searchTerm
              ? ['CARD_NO', 'STAFF_NAME', 'STAFF_EMAIL', 'STAFF_REMARKS'].join(
                  ','
                )
              : null,
            page: query.page ? (query.page - 1).toString() : undefined,
            limit: query.limit?.toString(),
            sortBy: query.sortBy,
            order: query.order,
            isAddFacility: query.isAddFacility ?? false,
            facilityTypes: query.facilityTypes?.join(','),
            floorIDsToBeUpdatedFacilities:
              query.floorIDsToBeUpdatedFacilities?.join(','),
          }),
        }
      )
      .pipe(
        map((resp) =>
          mapIListResponse(resp, mapAPIStaffAccessMassEditSubmission)
        )
      );
  }

  public massCancel(
    request: IAPIStaffAccessMassCancelRequest
  ): Observable<IAPIStaffAccessMassCancelResponse> {
    return this.http
      .put('requests/staff-access/mass/cancel', {
        ...mapMassEditActionCommonRequestParam(request),
      })
      .pipe(map((res) => mapIListResponse(res, mapMassActionResponseCard)));
  }

  public massAddFloors(
    request: IAPIStaffAccessMassAddFloorsRequest
  ): Observable<void> {
    return this.http
      .post('requests/staff-access/mass/addFloors', {
        ...mapMassEditActionCommonRequestParam(request),

        buildingNewAccessFloorIDs: request.floorIDs,
        defaultBuildingFloorID: request.defaultFloorID,
        buildingFloorFacilityIDs: request.buildingFloorFacilityIDs,
      })
      .pipe(mapTo(null));
  }

  public massRemoveFloors(
    request: IAPIStaffAccessMassRemoveFloorsRequest
  ): Observable<void> {
    return this.http
      .put('requests/staff-access/mass/removeFloors', {
        ...mapMassEditActionCommonRequestParam(request),

        toBeRemoveFloorIDs: request.floorIDs,
      })
      .pipe(mapTo(null));
  }

  public massSetDefaultFloor(
    request: IAPIStaffAccessMassSetDefaultFloorRequest
  ): Observable<void> {
    return this.http
      .post('requests/staff-access/mass/setDefaultFloor', {
        ...mapMassEditActionCommonRequestParam(request),
        defaultBuildingFloorID: request.defaultBuildingFloorID,
      })
      .pipe(mapTo(null));
  }

  public massAddFacilities(
    request: IAPIStaffAccessMassAddFacilitiesRequest
  ): Observable<void> {
    return this.http
      .put('requests/staff-access/mass/addFacilities', {
        ...mapMassEditActionCommonRequestParam(request),
        facilityTypes: request.facilityTypes,
        floorIDsToBeUpdatedFacilities: request.floorIDsToBeUpdatedFacilities,
      })
      .pipe(mapTo(null));
  }

  public massRemoveFacilities(
    request: IAPIStaffAccessMassRemoveFacilitiesRequest
  ): Observable<void> {
    return this.http
      .put('requests/staff-access/mass/removeFacilities', {
        ...mapMassEditActionCommonRequestParam(request),
        facilityTypes: request.facilityTypes,
        floorIDsToBeUpdatedFacilities: request.floorIDsToBeUpdatedFacilities,
      })
      .pipe(mapTo(null));
  }
}
