export interface Headers {
  get(name: string): string | null;
}

export function extractFileName(headers: Headers): string | null {
  const cd = headers.get('Content-Disposition');
  if (!cd) {
    return null;
  }
  const matches = /filename="([^"]+)"/.exec(cd);
  return matches?.[1] ?? null;
}
