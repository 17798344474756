import { mapMaybe } from '@core/util/function';
import {
  IAPIBuildingFloor,
  IAPIFacility,
  IAPIFormParameter,
  IAPIVisitor,
  IAPIVisitorAccessRequest,
  IAPIVisitorAccessSubmission,
  IAPIVisitorAccessSubmissionStatus,
} from '../models/visitor-access';
import { mapFormState } from './common-api';

export function mapBuildingParam(o: any): Map<number, IAPIFormParameter> {
  const buildingParamMap = new Map<number, IAPIFormParameter>();
  for (const [buildingID, formParameter] of Object.entries(o)) {
    buildingParamMap.set(Number(buildingID), mapFormParameter(formParameter));
  }
  return buildingParamMap;
}

export function mapFormParameter(o: any): IAPIFormParameter {
  return {
    emailState: mapFormState(o.emailState),
    facilityState: mapFormState(o.facilityState),
    floorState: mapFormState(o.floorState),
    phoneNoState: mapFormState(o.phoneNoState),
    defaultFloorState: mapFormState(o.defaultFloorState),
    visitorAccessHostEmailState: mapFormState(o.visitorAccessHostEmailState),
    visitorSdBfNoDay: o.visitorSdBfNoDay ?? 0,
    visitorOneTimeOrDayEntry: mapFormState(o.visitorOneTimeOrDayEntry),
    notifyState: mapFormState(o.notifyState),
    enableFacilitiesByFloor: o.enableFacilitiesByFloor ?? false,
    message: o.message,
    messageZhCN: o.messageZhCN,
    messageZhHK: o.messageZhHK,
  };
}

export function mapBuildingFloor(o: any): IAPIBuildingFloor {
  return {
    buildingID: o.buildingID,
    buildingFloorID: o.buildingFloorID,
    defaultFloor: {
      buildingFloorID: o.defaultFloor.buildingFloorID,
      floor: o.defaultFloor.floor,
      floorSequence: o.defaultFloor.floorSequence,
    },
    floor: o.floor,
    floorSequence: o.floorSequence,
    active: o.active,
    editable: o.editable,
  };
}

export function mapFacility(o: any): IAPIFacility {
  return {
    buildingFloorFacilityID: o.buildingFloorFacilityID,
    buildingFloorID: o.buildingFloorID,
    buildingFacility: {
      facilityType: o.buildingFacility.facilityType,
      description: o.buildingFacility.description,
      descriptionZhCN: o.buildingFacility.descriptionZhCN,
      descriptionZhHK: o.buildingFacility.descriptionZhHK,
      sequence: o.buildingFacility.sequence,
      status: o.buildingFacility.status,
    },
    editable: o.editable,
    status: o.status,
  };
}

export function mapStatus(o: any): IAPIVisitorAccessSubmissionStatus {
  return {
    code: o.code,
    description: o.description,
    descriptionZhCN: o.descriptionZhCN,
    descriptionZhHK: o.descriptionZhHK,
    sequence: o.sequence,
    labelColour: o.labelColour,
  };
}

export const mapVisitorStatus = mapStatus;

export function mapVisitor(o: any): IAPIVisitor {
  return {
    visitorID: o.visitorID,
    firstName: o.firstName,
    lastName: o.lastName,
    email: o.email,
    companyName: o.companyName ?? '',
    phoneNo: o.phoneNo,
    facilities: (o.facilities as any[]).map((f) => ({
      buildingFloorFacilityID: f.buildingFloorFacilityID,
    })),
    commonFacilities: o.commonFacilities,
    entryPassType: o.entryPassType,
    notifyUponArrival: o.notifyUponArrival,
    vmsJournalStatusInfo: mapMaybe(mapVisitorStatus)(o.vmsJournalStatusInfo),
  };
}

export function reverseMapVisitorAccessCreateRequest(
  o: IAPIVisitorAccessRequest
): any {
  return {
    visitingDate: o.visitingDate.toISOString(),
    meetingName: o.meetingName,
    contactFirstName: o.contactFirstName,
    contactLastName: o.contactLastName,
    contactNo: o.contactNo,
    contactEmail: o.contactEmail,
    buildingID: o.buildingID,
    accessFloors: o.accessFloors,
    defaultBuildingFloorID: o.defaultBuildingFloorID,
    visitors: o.visitors,
  };
}

export function mapAPIVisitorAccessSubmission(
  o: any
): IAPIVisitorAccessSubmission {
  return {
    visitorAccessID: o.visitorAccessID,
    version: o.version,
    createdByName: o.createdByName,
    createdDate: new Date(o.createdDate),
    lastUpdatedByName: o.lastUpdatedByName,
    lastUpdatedDate: new Date(o.lastUpdatedDate),
    editable: o.editable,
    writable: o.hasWriteAccess,
    visitingDate: new Date(o.visitingDate),
    buildingID: o.buildingID,
    meetingName: o.meetingName,
    contactFirstName: o.contactFirstName,
    contactLastName: o.contactLastName,
    contactEmail: o.contactEmail,
    contactNo: o.contactNo,
    visitors: (o.visitors as any[]).map(mapVisitor),
    accessFloors: (o.accessFloors as any[]).map((accessFloor) => ({
      buildingFloorID: accessFloor.buildingFloorID,
      leaseIdentifier: accessFloor.leaseIdentifier,
    })),
    defaultBuildingFloorID: o.defaultBuildingFloorID,
    referenceNo: o.referenceNo,
    status: o.status,
    reason: o.reason,
  };
}
