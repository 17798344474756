import {
  IAPIBuildingFloor,
  IAPIBuildingParams,
  IAPICardType,
  IAPIFacility,
  IAPIStaff,
  IAPIStaffAccessMassEditSubmission,
  IAPIStaffAccessSubmission,
  IAPIStaffAccessSubmissionStatus,
  MassActionResponseCard,
} from '../models/staff-access';
import { mapFormState } from './common-api';

export function mapAPIBuildingFloor(o: any): IAPIBuildingFloor {
  return {
    buildingID: o.buildingID,
    buildingFloorID: o.buildingFloorID,
    defaultFloor: {
      buildingFloorID: o.defaultFloor.buildingFloorID,
      floor: o.defaultFloor.floor,
      floorSequence: o.defaultFloor.floorSequence,
    },
    floor: o.floor,
    floorSequence: o.floorSequence,
    active: o.active,
    editable: o.editable,
  };
}

export function mapAPIFacility(o: any): IAPIFacility {
  return {
    buildingFloorFacilityID: o.buildingFloorFacilityID,
    buildingFloorID: o.buildingFloorID,
    buildingFacility: {
      facilityType: o.buildingFacility.facilityType,
      description: o.buildingFacility.description,
      descriptionZhCN: o.buildingFacility.descriptionZhCN,
      descriptionZhHK: o.buildingFacility.descriptionZhHK,
      sequence: o.buildingFacility.sequence,
      status: o.buildingFacility.status,
    },
    status: o.status,
  };
}

export function mapAPICardType(o: any): IAPICardType {
  return {
    active: o.active,
    code: o.code,
    description: o.description,
    descriptionZhCN: o.descriptionZhCN,
    descriptionZhHK: o.descriptionZhHK,
    labelColour: o.labelColour,
    sequence: o.sequence,
    tpShow: o.tpShow,
  };
}

export function mapStatus(o: any): IAPIStaffAccessSubmissionStatus {
  return {
    code: o.code,
    description: o.description,
    descriptionZhCN: o.descriptionZhCN,
    descriptionZhHK: o.descriptionZhHK,
    sequence: o.sequence,
    labelColour: o.labelColour,
  };
}

export function mapAPIStaffAccessSubmission(o: any): IAPIStaffAccessSubmission {
  return {
    staffAccessCardID: o.staffAccessCardID,
    staffAccessProfileID: o.staff.staffAccessProfileID,
    version: o.version,
    lastUpdatedByName: o.lastUpdatedByName,
    lastUpdatedDate: new Date(o.lastUpdatedDate),
    createdDate: new Date(o.createdDate),
    createdByName: o.createdByName,

    buildingID: o.buildingID,
    cardType: o.cardType,
    cardNo: o.cardNo,
    staff: {
      firstName: o.staff.firstName,
      lastName: o.staff.lastName,
      email: o.staff.email,
      remarks: o.staff.remarks,
    },
    accessFloors: o.accessFloors.map((f: any) => ({
      buildingFloorID: f.buildingFloorID,
    })),
    facilities: o.facilities.map((f: any) => ({
      buildingFloorFacilityID: f.buildingFloorFacilityID,
    })),
    commonFacilities: o.commonFacilities,
    defaultBuildingFloorID: o.defaultBuildingFloorID,
    grantedInfo: o.grantedInfo && {
      accessFloors: o.grantedInfo.accessFloors.map((f: any) => ({
        buildingFloorID: f.buildingFloorID,
      })),
      facilities: o.grantedInfo.facilities.map((f: any) => ({
        buildingFloorFacilityID: f.buildingFloorFacilityID,
      })),
      defaultBuildingFloorID: o.grantedInfo.defaultBuildingFloorID,
    },

    status: o.status,
    reason: o.reason || null,

    editable: o.editable,
  };
}

export function mapStaff(o: any): IAPIStaff {
  return {
    firstName: o.firstName,
    lastName: o.lastName,
    cardType: o.cardType,
    cardNo: o.cardNo,
    email: o.email,
    facilities: (o.facilities as any[]).map((f) => ({
      buildingFloorFacilityID: f.buildingFloorFacilityID,
    })),
    commonFacilities: o.commonFacilities,
    remarks: o.remarks,
    notProcess: o.notProcess,
  };
}

export function mapAPIBuildingParams(o: any): IAPIBuildingParams {
  return {
    maxRequest: o.maxRequestNo,
    cardFormats: (o.formats as any[]).map((c) => ({
      code: c.code,
      format: c.format,
      maxLength: c.maxLength,
    })),
    facilityState: mapFormState(o.facilityState),
    floorState: mapFormState(o.floorState),
    emailState: mapFormState(o.emailState),
    defaultFloorState: mapFormState(o.defaultFloorState),
    note: o.note,
    noteZhHK: o.noteZhHK,
    noteZhCN: o.noteZhCN,
    cardTypeUpdatable: o.cardTypeUpdatable,
    cardNoUpdatable: o.cardNoUpdatable,
    enableFacilitiesByFloor: o.enableFacilitiesByFloor,
  };
}

export function mapAPIStaffAccessMassEditSubmission(
  o: any
): IAPIStaffAccessMassEditSubmission {
  return {
    ...mapAPIStaffAccessSubmission(o),
    isRemovable: o.isRemovable,
  };
}

export function mapMassActionResponseCard(o: any): MassActionResponseCard {
  return {
    staffAccessCardID: o.staffAccessCardID,
    staffAccessProfileID: o.staffAccessProfileID,
    status: o.status,
  };
}
