export const identity = <T>(a: T): T => a;

export function mapList<T, U>(fmap: (a: T) => U): (list: T[]) => U[] {
  return (list: T[]): U[] => {
    return list.map(fmap);
  };
}

export function mapMaybe<T, U>(
  fmap: (a: T) => U
): (maybe: T | null) => U | null {
  return (maybe: T | null): U | null => {
    if (maybe == null) {
      return null;
    }
    return fmap(maybe);
  };
}
