import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiClient } from '@core/http/api-client';
import { AccessCardBatchUploadTimeoutError } from '@core/http/errors';
import { Observable } from 'rxjs';
import { catchError, map, mapTo } from 'rxjs/operators';
import {
  mapAPIBuildingCommonFacilityDTO,
  mapAPIEditableBuilding,
  mapIListResponse,
} from './mappers/common-api';
import {
  mapBuildingFloor,
  mapFacility,
  mapStatus,
  mapVisitor,
  mapAPIVisitorAccessSubmission,
  reverseMapVisitorAccessCreateRequest,
  mapBuildingParam,
} from './mappers/visitor-access-api';
import {
  APIBuildingCommonFacilityDTO,
  IAPIEditableBuilding,
  IListResponse,
} from './models/common';
import {
  IAPIBuildingFloor,
  IAPIFacility,
  IAPIVisitorAccessRequest,
  IAPIVisitorAccessSubmission,
  IAPIVisitorAccessSubmissionStatus,
  IAPIVisitorAccessSubmissionQuery,
  IAPIVisitorAccessTemplateRequest,
  IAPIDownloadTemplateResponse,
  IAPIVisitorAccessUploadUrl,
  IAPIVisitorAccessUploadRequest,
  IAPIVisitor,
  IAPIFormParameter,
} from './models/visitor-access';
import { extractFileName } from './utils/attachments';
import { formatDate } from './utils/formatter';
import { createHttpParams } from './utils/params';

@Injectable({
  providedIn: 'root',
})
export class VisitorAccessAPIService {
  constructor(private readonly http: ApiClient) {}

  getBuildings(): Observable<IAPIEditableBuilding[]> {
    return this.http
      .get<IListResponse<IAPIEditableBuilding>>(
        'requests/visitor-access/buildings'
      )
      .pipe(map((resp) => resp.list.map(mapAPIEditableBuilding)));
  }

  getBuildingParams(): Observable<Map<number, IAPIFormParameter>> {
    return this.http
      .get('requests/visitor-access/parameters')
      .pipe(map((resp) => mapBuildingParam(resp)));
  }

  getFloors(
    visitingDate: Date,
    submissionID?: number
  ): Observable<IAPIBuildingFloor[]> {
    const params: Record<string, string> = {
      visitingDate: formatDate(visitingDate),
    };
    if (submissionID != null) {
      params.visitorAccessID = String(submissionID);
    }
    return this.http
      .get<IListResponse<any>>(`requests/visitor-access/buildings/floors`, {
        params,
      })
      .pipe(map((resp) => resp.list.map(mapBuildingFloor)));
  }

  getBuildingFloors(buildingID: number): Observable<IAPIBuildingFloor[]> {
    return this.http
      .get<IListResponse<any>>(
        `requests/visitor-access/buildings/${buildingID}/floors`
      )
      .pipe(map((resp) => resp.list.map(mapBuildingFloor)));
  }

  getFacilities(
    buildingID: number,
    visitingDate: Date,
    submissionID?: number
  ): Observable<IAPIFacility[]> {
    const params: Record<string, string> = {
      visitingDate: formatDate(visitingDate),
    };
    if (submissionID != null) {
      params.visitorAccessID = String(submissionID);
    }
    return this.http
      .get<IListResponse<any>>(
        `requests/visitor-access/buildings/${buildingID}/facilities`,
        { params }
      )
      .pipe(map((resp) => resp.list.map(mapFacility)));
  }

  getBuildingCommonFacilities(
    buildingID: number
  ): Observable<APIBuildingCommonFacilityDTO[]> {
    return this.http
      .get(`requests/visitor-access/buildings/${buildingID}/common-facilities`)
      .pipe(
        map(mapIListResponse(mapAPIBuildingCommonFacilityDTO)),
        map((dto) => dto.list)
      );
  }

  getStatuses(): Observable<IAPIVisitorAccessSubmissionStatus[]> {
    return this.http
      .get<IListResponse<any>>('requests/visitor-access/status')
      .pipe(map((resp) => resp.list.map(mapStatus)));
  }

  getSubmissions(
    query: IAPIVisitorAccessSubmissionQuery
  ): Observable<IListResponse<IAPIVisitorAccessSubmission>> {
    return this.http
      .get<IListResponse<any>>('requests/visitor-access/submissions', {
        params: createHttpParams({
          ...query,
          status: query.status?.join(','),
          buildingIDs: query.buildingIDs?.join(','),
          startDate: query.startDate && formatDate(query.startDate),
          endDate: query.endDate && formatDate(query.endDate),
          mySubmission: query.mySubmission?.toString(),
          searchFields: query.searchFields?.join(','),
          page: query.page ? (query.page - 1).toString() : undefined,
          limit: query.limit?.toString(),
        }),
      })
      .pipe(
        map((resp) => mapIListResponse(resp, mapAPIVisitorAccessSubmission))
      );
  }

  createRequest(
    request: IAPIVisitorAccessRequest,
    allowDuplicate?: boolean
  ): Observable<IAPIVisitorAccessSubmission> {
    const params: Record<string, string> = {};
    if (allowDuplicate != null) {
      params.allowDuplicate = String(allowDuplicate);
    }
    return this.http
      .post<IAPIVisitorAccessSubmission>(
        'requests/visitor-access',
        reverseMapVisitorAccessCreateRequest(request),
        { params }
      )
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 504) {
            throw new AccessCardBatchUploadTimeoutError();
          }
          throw err;
        }),
        map(mapAPIVisitorAccessSubmission)
      );
  }

  getSubmission(id: number): Observable<IAPIVisitorAccessSubmission> {
    return this.http
      .get<IAPIVisitorAccessSubmission>(`requests/visitor-access/${id}`)
      .pipe(map(mapAPIVisitorAccessSubmission));
  }

  editRequest(
    request: IAPIVisitorAccessRequest,
    id: number,
    allowDuplicate?: boolean
  ): Observable<IAPIVisitorAccessSubmission> {
    const params: Record<string, string> = {};
    if (allowDuplicate != null) {
      params.allowDuplicate = String(allowDuplicate);
    }
    return this.http
      .put<IAPIVisitorAccessSubmission>(
        `requests/visitor-access/${id}`,
        reverseMapVisitorAccessCreateRequest(request),
        { params }
      )
      .pipe(
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 504) {
            throw new AccessCardBatchUploadTimeoutError();
          }
          throw err;
        }),
        map(mapAPIVisitorAccessSubmission)
      );
  }

  cancelRequest(id: number, reason: string | null): Observable<void> {
    return this.http
      .post(`requests/visitor-access/${id}`, { reason })
      .pipe(mapTo(undefined));
  }

  downloadTemplate(
    templateInfo: IAPIVisitorAccessTemplateRequest
  ): Observable<IAPIDownloadTemplateResponse> {
    return this.http
      .post(`requests/visitor-access/batch/template`, templateInfo, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        map((resp) => {
          if (!resp.body) {
            throw new Error('Received empty response');
          }
          return {
            fileName: extractFileName(resp.headers) ?? 'template.csv',
            data: resp.body,
          };
        })
      );
  }

  public getUploadUrl(
    fileName: string,
    contentType: string
  ): Observable<IAPIVisitorAccessUploadUrl> {
    return this.http
      .post<any>('requests/visitor-access/batch/upload-url', {
        fileName,
        contentType,
      })
      .pipe(map((resp) => ({ url: resp.url, objectKey: resp.objectKey })));
  }

  public uploadTemplate(
    request: IAPIVisitorAccessUploadRequest
  ): Observable<IAPIVisitor[]> {
    return this.http
      .post<any>('requests/visitor-access/batch/upload', request)
      .pipe(map((resp) => resp.list.map(mapVisitor)));
  }
}
